@import 'src/assets/style/utils/variables.module';

.campaign-drawer-updated {
  height: 100%;
  font-family: 'Lato';
  display: flex;
  flex-direction: column;
  .icon-updated {
    position: absolute;
    top: 10px;
    right: 10px
  }
  .campaign-drawer-img {
    height: 30%;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
  }
  .updated-img {
    min-height: 200px !important;
  }
  .campaign-drawer-details {
    padding: 1em;
    overflow: auto;
    flex: 1;
    .subheader-1 {
      text-transform: uppercase;
      color: #3E5656;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0.25em;
    }
    .title {
      color: black;
      font-size: 18px;
      font-weight: 700;
    }
    .badges-container {
      margin-top: 0.25em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .icon__ico {
        width: 18px;
        @media (min-width: $breakpoint-md) {
          font-size: 18px;
        }
      }
    }
    .points {
      margin-top: 1em;
      width: fit-content;
      border-radius: 1000px;
      padding: 6px 10px 4px 10px;
      font-size: 14px;
      font-weight: 600;
      color: var(--points-badge-text);
      min-width: 4em;
      text-align: center;
      min-height: 1.5em;
      background-color: var(--points-badge);
    }
    .locked-background {
      color: white;
      background-color: #677979;
    }
    .description {
      font-size: 16px;
      .wmde-markdown {
        margin: 1em 0;
      }
    }
    .description-updated {
      margin-bottom: -1em;
    }
    .unavailable-campaign-text {
      color: #B50010;
      font-weight: 700;
      .info {
        font-weight: 400;
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
  }
  .cta {
    margin: 1em;
    margin-top: 0;
    .btn {
      display: block;
      width: 100%;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
    }
  }
  .cta-updated {
    margin: 1em 2em;
    margin-top: 0;
  }
  .campaign-drawer__timer{
    font-size: 14px;
  }
  .progress-upper{
    font-size: 14px;
  }
  .progress-outer{
    height: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .progress-wrapper{
    margin-top: 20px;
  }
  .p-2 {
    padding: 1.5em 2em;
  }
  .action-section {
    width: 95%;
    background-color:#FFF1F1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: .5em;
    cursor: pointer;
  }
  .action-info {
    font-size: 14px;
    font-weight: 700;
    border: 1px solid #D7DFE5;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    .left-section {
      display: flex;
      align-items: center;
      flex: 1;
      gap: .35em;
      svg {
        color: #A71E24;
      }
    }
    .right-section {
      display: flex;
      align-items: center;
      svg {
        color: #8DA0AE;
      }
    }
  }
  .action-title {
    color: black;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: .25em;
  }
}

.maxh-75 {
  max-height: 75vh;
}
.mb-1 {
  margin-bottom: 1em;
}
