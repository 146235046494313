@import 'src/assets/style/utils/variables.module';

.campaign-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: 'Lato';
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  justify-content: flex-start;
  .campaigns-details-container {
    margin: 0 1em 0.5em 1em;
    word-wrap: break-word;
    .subheader-1 {
      margin-top: 10px;
      color: #4B5054;
      font-size: 13px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      @media (min-width: $breakpoint-md) {
        font-size: 16px;
      }
    }
    .campaign-title {
      margin-top: 8px;
      font-size: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      @media (min-width: $breakpoint-md) {
        font-size: 20px;
      }
    }
    .subheader-2 {
      color: #647588;
      font-weight: 400;
      margin-top: 8px;
      margin-bottom: 7px;
      font-size: 11px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      @media (min-width: $breakpoint-md) {
        font-size: 14px;
      }
    }
    .fw-700 {
      font-weight: 700;
    }
    .mb-1 {
      margin-bottom: 1em;
    }
    .campaigns-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      min-height: 3.5em;
      @media (max-width: $breakpoint-md) {
        font-size: 9px;
        min-height: 4em;
      }
      .campaign-status {
        .locked-text {
          color: #B50010;
        }
        .sold-out-text {
          color: #B50010;
        }
      }
      .campaign-points {
        width: fit-content;
        border-radius: 1000px;
        padding: 6px 12px 4px 12px;
        font-size: 13px;
        font-weight: 500;
        color: var(--points-badge-text);
        min-width: 4em;
        text-align: center;
        min-height: 1.5em;
        background-color: var(--points-badge);
        @media (max-width: $breakpoint-md) {
          font-size: 11px;
          min-width: 3em;
        }
      }
      .locked-background {
        color: white;
        background-color: #677979;
      }
    }
  }
  .campaign-img {
    background-size: cover;
    background-position: center;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .desktop {
    width: 90%;
    margin-bottom: 0.5em;
  }
  .mobile {
    width: 85%;
    margin-bottom: 1em;
  }
  .mt-1 {
    margin-top: 1em !important;
  }
  .mt-05 {
    margin-top: 0.5em;
  }
}
.mb-075 {
  margin-bottom: 0.75em;
}
