@import 'src/assets/style/utils/variables.module';

.base-drawer {
  align-items: flex-end;

  &__title {
    &__wrapper {
      display: flex;
      padding: 32px 16px 8px;
      justify-content: space-between;
      align-items: center;
    }

    &__hidden {
      width: 40px;
    }
  }

  &-modal {
    flex-basis: 100%;
    padding: 32px 16px 24px;

    &__side {
      width: 100%;
      max-height: 60%;
      margin: 0;
      border-radius: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: 0 16px 24px;

    &__no-padding {
      padding: 0 !important;
    }
  }

  &-close {
    color: #3E5656;

    .MuiSvgIcon-root {
      font-size: 16px;
    }
  }

  @media (min-width: $breakpoint-md) {
    align-items: unset;
    justify-content: flex-start;

    &__title {
      &__wrapper {
        padding: 32px 80px 0;
      }

      &__back__ico {
        margin-right: 8px;
        font-size: 24px;
        color: #191919;
      }
    }

    &__content {
      padding: 0 80px 24px;
    }

    &-modal {
      flex-basis: auto;
      padding: 32px 80px 24px;

      &__side {
        width: 700px;
        max-width: 700px;
        max-height: 100%;
      }
    }

    &-close {
      color: #191919;
    }
  }

  .mb-2 {
    margin-bottom: 2em;
  }
}

.drawer-updated {
  position: absolute;
  bottom: 0;
  max-width: unset;
  z-index: 999;
  height: 96% !important;
  max-height: 96% !important;
  border-radius: 24px 24px 0px 0px;
  .h-100 {
    height: 100%;
  }
  .p-0 {
    padding: 0;
  }
  .base-drawer__title__wrapper {
    padding: 18px 16px !important;
  }
  &__desktop {
    width: 540px !important;
    height: 395px;
    .base-drawer__content__no-padding{
      overflow-y: auto !important;
    }
  }
}

.base-drawer-centered {
  width: 50%;
  border-radius: 16px;
  max-height: 85%;
  position: absolute;
  .p-0 {
    padding: 0 !important;
  }
  
  .h-100 {
    max-height: 80vh;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 20px;
  }
}

.drawer-button {
  position: absolute;
  right: 1em;
}

.base-drawer-center {
  align-items: center;
  justify-content: center;

  .base-drawer-modal {
    flex-basis: auto;
    padding: 31px 25px;
    border-radius: 10px;
  }

  @media (min-width: $breakpoint-md) {
    .base-drawer-modal__side {
      width: auto;
      border-radius: 10px;
    }
  }
}
.base-drawer__link__txt.current_langauge {
  font-weight: 400;
}
