@import "src/assets/style/utils/variables.module";

.welcome-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px -6px !important;

  &__icon {
    max-width: 100% !important;
    @media (min-width: $breakpoint-md) {
      max-width: 60% !important;
    }
  }

  &__title {
    margin-top: 18px;
    font-size: 18px;
    font-weight: bold;
  }

  &__description {
    margin-top: 12px !important;
    margin-bottom: 16px;
    font-size: 14px;
    p { 
      max-height: 100px;
      overflow: scroll;
      margin: 0;     
    }
  }

  &__btn {
    margin-top: 12px !important;
    margin-bottom: -14px;
    font-weight: bold;
  }

  @media (min-width: $breakpoint-md) {
    &__btn {
      margin-bottom: 0;
    }
  }
}