@import 'src/assets/style/utils/variables.module';
.spinner-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: center;
  .spinner-holder {
    width: 100%;
    height: 140px;
  }
}
.sign-in-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  .sign-in-left {
    width: 50vb;
    flex-grow: 1;
    background-color: #edf2f4;
    display: flex;
    align-items: center;
    justify-content: center;

    .form-fields-box {
      height: 45em;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 1024px) {
        width: 25em;
      }
      @media (max-width: 1023px), (max-height: 679px) {
        width: 90%;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      @media (max-height: 740px) and (min-width: 1024px) {
        height: 100%;
      }

      .MuiFilledInput-root {
        background-color: white !important;
        border: 2px solid #bfc7cd !important;
        border-radius: 8px;
      }

      .MuiFilledInput-underline::before {
        visibility: hidden;
      }

      .MuiFilledInput-underline::after {
        visibility: hidden;
      }

      .tenant-logo {
        margin-top: 2em;
        margin-bottom: 7em;
        @media (max-width: 1023px), (max-height: 679px) {
          margin-top: 3em;
          text-align: center;
          margin-bottom: 2em;
        }
        img {
          max-width: 9em;
          height: 1.5em;
        }
        .custom-img {
          max-width: 15em !important;
          height: 4.5em !important;
        }
      }

      .sign-in-heading {
        font-family: 'Lato';
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 0.75em;
        color: #000000;
        @media (max-width: 1023px), (max-height: 679px) {
          text-align: center;
          font-size: 24px;
          margin-bottom: 1.5em;
        }
        @media (max-width: 1023px) and (max-height: 500px) {
          font-size: 22px;
          margin-bottom: 0.5em;
        }
        @media (min-width: 1024px) and (max-height: 680px) {
          font-size: 28px;
        }
        @media (min-width: 1024px) and (max-height: 540px) {
          font-size: 24px;
        }
        @media (max-width: 1023px) and (min-height: 1000px) {
          font-size: 28px;
        }
      }

      .sign-in-actions {
        margin-top: 2em;
        @media (max-width: 1023px) {
          margin-top: 0;
        }
        @media (max-width: 1023px) and (max-height: 460px) {
          margin-bottom: 0;
        }
        .link-text {
          color: var(--link-color);
          font-size: 14px;
          font-family: 'Lato';
          @media (max-width: 1023px) and (min-height: 1000px) {
            font-size: 18px;
          }
        }
        .text {
          color: #000000;
          font-size: 14px;
          font-family: 'Lato';
          margin-right: 0.25em;
          @media (max-width: 1023px) and (min-height: 1000px) {
            font-size: 18px;
          }
        }
      }

      .disabled {
        background-color: var(--disable) !important;
        color: var(--disable-text) !important;
      }

      .sign-in-form {
        width: auto !important;
        @media (max-width: 1023px), (max-height: 679px) {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
        .MuiBox-root {
          @media (max-width: 1023px) and (max-height: 500px) {
            margin-bottom: 0.75em;
          }
        }
      }

      .responsive-btn {
        @media (max-width: 1023px), (max-height: 679px) {
          margin-top: 2em;
          margin-bottom: 2em;
        }
        .MuiButton-label {
          @media (max-width: 1023px) and (min-height: 1000px) {
            font-size: 18px;
          }
        }
      }
    }
  }

  .sign-in-right {
    flex-grow: 1;
    width: 50vb;
    display: flex;
    align-items: center;
    .steps-box {
      height: fit-content;
      max-height: 80%;
      margin-right: auto;
      margin-left: auto;
      min-width: 20em;
      max-width: 55%;
      padding: 3em;
      padding-top: 4em;
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.1)
      );
      backdrop-filter: blur(40px);
      -webkit-backdrop-filter: blur(40px);
      border-radius: 20px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.2);
      overflow: auto;
      .welcome-box {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        margin-bottom: 2em;
        max-width: 16em;
        align-items: center;
        text-align: center;
        .MuiTypography-root {
          font-family: 'Lato';
          font-size: 24px;
          font-weight: 700;
        }
        img {
          max-width: 160px;
          height: 120px;
        }
      }
      .step-info {
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
        margin-right: auto;
        margin-left: auto;
        .MuiTypography-root.description {
          font-size: 12px;
          font-family: 'Lato';
        }
        .step-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5em;
          img {
            width: 50px;
            height: 50px;
          }
          .MuiTypography-root {
            font-family: 'Lato';
            font-size: 16px;
          }
          .MuiTypography-root.description {
            font-size: 12px !important;
            font-family: 'Lato';
          }
        }
      }
    }
  }

  .inline {
    display: inline;
  }

  .no-decor {
    text-decoration: none;
  }

  .forgot-button {
    display: inline-block;
    width: fit-content;
  }

  .clearfix {
    clear: both;
  }

  .lato,
  .MuiInputBase-root {
    font-family: 'Lato' !important;
  }

  .MuiInputBase-input {
    font-size: 16px;
    @media (max-width: 1023px) and (min-height: 1000px) {
      font-size: 18px;
    }
  }

  .label {
    color: #647588;
    @media (max-width: 1023px) and (min-height: 1000px) {
      font-size: 18px;
    }
  }

  .MuiIconButton-label {
    .MuiSvgIcon-root {
      color: #647588 !important;
      @media (max-width: 500px) {
        font-size: large;
      }
    }
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 20px;
  }
  .MuiFormHelperText-root.Mui-error {
    color: var(--error);
  }
}
