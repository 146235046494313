@import 'src/assets/style/utils/variables.module';

.header {
  background: var(--header);
  box-shadow: none;
  height: 70px;
  z-index: 100;
  position: sticky;

  &__new {
    height: 79px;
    .header-toolbar {
      height: 79px;
    }
    .manage-account-select,
    .multi-lang-select {
      margin-left: -10px !important;
      .MuiSelect-select:focus {
        background-color: unset !important;
      }
    }
    .multi-lang-action {
      min-width: 4em;
    }
    .multi-lang-name {
      padding-left: 15px;
    }
  }
  &-toolbar {
    height: 70px;
    max-width: $main-content-max-width;
    padding: 0 $main-content-padding-xs;
    justify-content: space-between;

    &__ico {
      font-size: 22px;
    }

    &__logo {
      width: 150px;
      height: 60%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &--loading__nav-tab {
      max-width: 100%;
      width: 100%;
      height: 104px;
      @media (min-width: $breakpoint-md) {
        height: 79px;
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1.5em;

    &__item {
      margin-left: 10px;
      color: var(--primary-text);
    }
  }

  &--mobile {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #edf2f4;
  }

  @media (min-width: $breakpoint-md) {
    position: fixed;
    top: 0;

    &-toolbar {
      padding: 0 0.5% 0 1%;
    }

    &-actions {
      &__item {
        margin-left: 40px;
      }
    }
  }

  @media (min-width: 1060px) {
    &-toolbar {
      padding: 0 1% 0 2%;
    }
  }

  @media (min-width: 1142px) {
    &-toolbar {
      padding: 0 1% 0 5%;
    }
  }

  @media (min-width: 1237px) {
    &-toolbar {
      padding: 0 5% 0 12%;
    }
  }

  @media (min-width: 1347px) {
    &-toolbar {
      padding: 0 $main-content-padding-md;
    }
  }

  @media (max-width: 1024px) {
    &-actions {
      &__item {
        margin-left: 0;
      }
    }
  }

  @media (min-width: calc(#{$main-content-max-width} + #{$main-content-padding-md} * 2)) {
    &-toolbar {
      width: $main-content-max-width;
      margin: auto;
    }
  }

  .manage-account-action,
  .multi-lang-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--header-text);
    .MuiSelect-icon {
      color: white;
    }
    .MuiSvgIcon-root {
      position: absolute;
      pointer-events: none;
      margin-top: 0.15em;
      margin-left: 0.5em;
      color: var(--header-text) !important;
    }
    .MuiInput-underline::before,
    .MuiInput-underline::after {
      border-bottom: none !important;
    }
    .manage-account-select {
      background-color: transparent;
      min-width: 2em;
      cursor: pointer;
      .MuiSelect-select {
        padding-right: 0px;
      }
    }
  }
}

#user-account-menu .MuiPaper-root {
  border-radius: 12px !important;
  margin-top: 0.5em;
  .MuiMenuItem-root {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;
  }
  .sign-out {
    color: #a71e24;
  }
  .MuiList-root {
    padding: 12px 0px;
    padding-right: 5px;
  }
}
@media (min-width: $breakpoint-md) {
  .menu-item__desktop {
    font-weight: 400 !important;
  }
  .selected-menu {
    font-weight: 700 !important;
  }
}
