.spinner-container {
  position:absolute;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  align-items: center;
  .spinner-holder {
    width: 100%;
    height: 140px;
  }
  background-color: white;
  z-index: 1;
}
.sign-up-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  box-sizing: border-box;

  .sign-up-left {
    width: 50vb;
    flex-grow: 1;
    background-color: #edf2f4;
    display: flex;
    align-items: center;
    justify-content: center;
    .h-100 {
      height: 100%;
    }
    .w-100 {
      width: 100%;
    }
    .form-fields-box {
      height: 40em;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 1024px) {
        width: 25em;
      }
      @media (max-width: 1023px), (max-height: 680px) {
        width: 80%;
        height: fit-content;
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 350px) {
        width: 90%;
      }
      .back-to-sign-in {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        margin-bottom: 2em;
        margin-top: 6em;
        @media (max-height: 780px) {
          margin-top: -2em;
        }
        @media (max-width: 1023px) {
          margin-top: 2em;
        }
        .MuiTypography-root {
          font-family: 'Lato';
          font-size: 14px;
        }
      }
      .MuiFilledInput-root {
        background-color: white !important;
        border: 2px solid #bfc7cd !important;
        border-radius: 8px;
      }
      .MuiFilledInput-underline::before {
        visibility: hidden;
      }
      .MuiFilledInput-underline::after {
        visibility: hidden;
      }
      .tenant-logo {
        margin-bottom: 7em;
        margin-top: -1.5em;
        @media (max-width: 1023px), (max-height: 680px) {
          text-align: center;
          margin-bottom: 2em;
        }
        img {
          max-width: 15em;
          height: 4.5em;
        }
      }
      .sign-up-heading {
        font-family: 'Lato';
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 0.35em;
        color: #000000;
        @media (max-width: 1023px), (max-height: 680px) {
          text-align: center;
          font-size: 24px;
          margin-bottom: 1em;
        }
      }
      .disabled {
        background-color: var(--disable) !important;
        color: var(--disable-text) !important;
      }
    }
    .MuiFormHelperText-root.Mui-error {
      color: var(--error);
    }
  }

  .sign-up-right {
    flex-grow: 1;
    width: 50vb;
    display: flex;
    align-items: center;
    .steps-box {
      height: fit-content;
      max-height: 80%;
      margin-right: auto;
      margin-left: auto;
      min-width: 20em;
      max-width: 55%;
      padding: 3em;
      padding-top: 4em;
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.1)
      );
      backdrop-filter: blur(40px);
      -webkit-backdrop-filter: blur(40px);
      border-radius: 20px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.2);
      overflow: auto;
      .welcome-box {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        margin-bottom: 2em;
        align-items: center;
        max-width: 16em;
        text-align: center;
        @media (min-width: 1024px) and (max-height: 720px) {
          margin-bottom: 2em;
        }
        .MuiTypography-root {
          font-family: 'Lato';
          font-size: 24px;
          font-weight: 700;
        }
        img {
          max-width: 160px;
          height: 120px;
        }
      }
      .step-info {
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
        margin-right: auto;
        margin-left: auto;
        .MuiTypography-root.description {
          font-size: 12px;
          font-family: 'Lato';
        }
        .step-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5em;
          img {
            width: 50px;
            height: 50px;
          }
          .MuiTypography-root {
            font-family: 'Lato';
            font-size: 16px;
          }
          .MuiTypography-root.description {
            font-size: 12px !important;
            font-family: 'Lato';
          }
        }
      }
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 20px;
    }
  }

  .inline {
    display: inline;
  }

  .no-decor {
    text-decoration: none;
  }

  .lato,
  .MuiInputBase-root {
    font-family: 'Lato' !important;
  }

  .font-size,
  .MuiInputBase-input {
    font-size: 16px;
    font-family: 'Lato'
  }

  .label {
    color: #647588;
  }

  form {
    width: 100%;
    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
    }
  }

  .overflow {
    overflow: auto;
  }

  .mb-15 {
    margin-bottom: 15% !important;
  }
}

.additional-form-box-mob {
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  .additional-fields-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .back-to-sign-in {
    color: black;
    margin-top: 20%;
  }
  .sign-up-heading {
    font-size: 24px;
    font-family: 'Lato';
    font-weight: 700;
    align-self: center;
    margin-top: 2em;
    margin-bottom: 0.25em;
  }
  .center {
    text-align: center;
  }
  .mt-2 {
    margin-top: 2em;
  }
  .mobile-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3em;
    align-items: center;
    .discard-box {
      margin-top: 0;
    }
    .btn {
      width: 40%;
    }
  }
}
.additional-fields {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  .field-responsive {
    width: 100%;
  }
  .MuiFormHelperText-root {
    margin-bottom: -1em;
    font-family: 'Lato';
  }
  .MuiIconButton-label {
    .MuiSvgIcon-root {
      color: #647588 !important;
    }
  }
}

.discard-box {
  margin-top: 2em;
  text-align: center;
  cursor: pointer;
  .MuiTypography-root {
    color: #191919;
    text-decoration: underline;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
  }
}
