@import 'src/assets/style/utils/variables.module';

.navigation-tabs {
  background: #EDF2F4;

  .MuiTab-root {
    text-transform: none;
    font-family: 'Lato';
    font-weight: 400;
    color: #2f363e;
    font-size: 14px;
    line-height: 20px;

    &:focus-visible {
      border: 1px solid;
    }

  }

  .MuiTabs-indicator {
    background: none;
  }

  [aria-selected="true"] {
    color: black;
    font-weight: 700;

    &:before {
      content: "";
      position: absolute;
      right: 15px;
      bottom: 0;
      left: 15px;
      height: 2px;
      background: var(--primary);
    }
  }
}